import React from 'react';
import './shipping.css';
import { Star } from '@material-ui/icons';
import bull from '../images/jk.png';

const Shipping = () => {
  return (<div className='about'>
      <div className='about-content'>
        <div className='about-header'>
            <h1>Payment & Shipping</h1>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;
            <div className='divider'></div>
        </div>
        <div className='about-desc'>
          <div className='about-us'>
            <h2>SHIPPING</h2>
            <br/>
            <p>
                <img src={bull} alt='Russian blue kittens' height="300" width="280" />
                    If you are interested in any of our Russian blue kittens on our website and live too far to drive

                    down and pick up the Kitten in person, then we can make the arrangements for you to have

                    your Kitten on a next or same day delivery. The Kitten will be shipped to the closest airport

                    near you or hand delivered to your home. Delivery prices are per county, region. In most

                    cases the Kitten will arrive within 48 hours within the US and 120 hours max international

                    delivery from the departure time. However, sometimes delays do occur due to weather or

                    pet checking, so check the flight with the shipping company before you leave your home to

                    go to the airport.
            
            <br/>
            <br/>
            <h2>GENERAL AIRLINE REQUIREMENTS</h2>
           
            <ol>
              <li>The Kitten must be at least 8 weeks 4 days old</li>
              <li>Must be accompanied with a veterinarian Health/Acclimation Certificate</li>
              <li>Some airlines have restrictions for temperature and will check destination and arrival flight temperature forecasts</li>

            </ol>
            <h3>Cost of Shipping via airline</h3>
            <ol>
              <li>veterinarian Health/Acclimation Certificate Approx</li>
              <li>Cost of Travel Russian blue kitten (depends on size of crate)</li>
              <li>Airfreight is determined by the weight of the Kitten and the weight/size of the Crate (Larger/heavier and older cats can cost more)</li>
            </ol>
            </p>
            <br/>
            <h3>SAME DAY DELIVERY IN USA & CANADA</h3>
            <br/>
            <p>If you decide to have our Russian blue kitten shipped to you, we will need the following information:</p>
            <p>
              <ol>
                <li>Your full name, that you have I.D. for, you will need to show this at the airport cargo desk when you pick up your Kitten</li>
                <li>The full name of someone else who also has I.D., as a “back up’ person for pickup so they can pick up your Kitten, if, in case of an emergency you are unable.</li>
                <li>Your physical address, it cannot be a post office box</li>
                <li>Phone numbers that you can easily be reached at, preferably at least two numbers.</li>
                <li>The name of the country that you live in</li>
                <li>The name of the airport that you prefer your Kitten to arrive at, and the name of an optional airport</li>
                <li>What Russian blue kitten are you interested in.</li>
              </ol>
            </p>
            <br/>
              <h3>Safe and Comfortable Shipping process</h3>
            <p>
                 Russian blue kittens aren’t vases or art supplies, so when you hear that he or she can be shipped to you, your first reaction is probably shock. Before you panic, get that picture of cardboard boxes and foam peanuts out of your mind! Shipping Russian blue kittens are usually quite safe and any airlines or trucking companies that take live animals have procedures in place to be sure they arrive in good health.

                 Airlines will either ship pets in the cargo compartment which sounds a bit callous and scary, but is actually fairly safe and comfortable for the pets, especially if there is no need for a layover or cargo transfer or the pets to be stowed under the passenger’s seat in lieu of carry on luggage. This is probably the safest mode of air travel.

                   We always give all our Russian blue kittens a Bordetella vaccine prior to shipping to avoid the chances of other Kittens or pets infecting them.
            </p>
          </div>
        </div>
      </div>
     </div>
  );
}
export default Shipping;
