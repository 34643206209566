import React, { useEffect, useState } from 'react'
import { CartState } from '../../context/Context';
import './Cart.css';

const Cart = (props) => {
    const {
        state: {cart},
        dispatch,
    } = CartState();
    const [total, setTotal] = useState();
    useEffect(()=>{
      setTotal(
        cart.reduce((acc, curr) => acc + Number(curr.price)*curr.qty, 0)
      
      )
    }, [cart]);

    const handleChange = (prod, e) => {
        dispatch({
          type: "CHANGE_CART_QTY",
          payload: {
            id: prod.id,
            qty: e.target.value,
          },
    })
    }

  return (
    <div className='poduct-cart'>
        <div className='product-container'>
              { cart.length > 0 ?
                cart.map((prod)=>(
                  <div className='cart-item'>
                        <img src={prod.pic} alt="Product Image" />
                        <div class="item-details">
                            <h4>{prod.name} {prod.option && <span>& {prod.option}</span>} </h4>
                            <p>${prod.price}</p>
                        </div>
                        <div class="item-actions">
                            <button onClick={()=>{
                              const name = prod.name;
                              const numberInput = document.getElementById(name);
                              if(numberInput.value > 1){
                                let newVal = (Number)(numberInput.value) - 1;
                                numberInput.value = newVal;
                                const event = { target: { value: newVal } };
                                handleChange(prod, event);
                              }
                            }}>-</button>
                            <input id={prod.name} value={prod.qty} onChange={(e)=>handleChange(prod, e)} type="number" min="1" />
                            <button onClick={()=>{
                              const name = prod.name;
                              const numberInput = document.getElementById(name);
                                let newVal = (Number)(numberInput.value) + 1;
                                numberInput.value = newVal;
                                const event = { target: { value: newVal } };
                                handleChange(prod, event);
                            }}>+</button>
                            <button class="remove-btn" onClick={
                              ()=>{
                                dispatch({
                                  type: 'REMOVE_FROM_CART',
                                  payload: prod
                                })
                              }
                            }>Remove</button>
                         </div>
                  </div>
                )): (<div className='cart-item'>No item in cart</div>)
              }
        </div>
        <div className='filters summary'>
          <span className='title' >Subtotal ({cart.length})</span>
          <span style={{fontWeight: 700, fontSize: 20}}>Total: ${total}</span>
           {total === 0 ? (<button style={{cursor: "not-allowed"}} type='button' disabled={cart.length === 0}>
            Proceed to Checkout
          </button>):(<button type='button' disabled={cart.length === 0}>
            Proceed to Checkout
          </button>)}
        </div>
    </div>
  )
}

export default Cart;