import React from 'react';
import './Contact.css';
import { Star } from '@material-ui/icons';
import bulldog from '../images/contact.png';
import emailjs from 'emailjs-com';

const Contact = () => {

    const sendEmail = (e) => {
		e.preventDefault();
	
		emailjs.sendForm('service_d9tyqqt', 'template_vr37jrs', e.target, 'user_OFw3EhyNs5ZyO8trDcOAJ')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });

		  e.target.reset();
          document.getElementById("success").style.display = "block";
	  };

  return <div className='contact'>
      <div className='contact-header'>
              <h1>Contact Us</h1>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;
              <div className='divider'></div>
      </div>
      <div className='contact-content'>
              <div className='contact-container'> 
                 <form onSubmit={sendEmail}>
                     <label for="fname">Your Name *</label>
                     <input type="text" id="fname" name="name" placeholder="Your name.." required />
                     <label for="email">Your Email *</label>
                     <input type="text" id="email" name="email" placeholder="Your email.." required />
                     <label for="phone">Your Phone Number *</label>
                     <input type="text" id="phone" name="phone" placeholder="Your phone number.." required />
                     <label for="address">Your Address *</label>
                     <input type="text" id="address" name="address" placeholder="Your home address.." required />
                     <label for="subject">Type your message here...</label>
                     <textarea id="subject" name="message" placeholder="Write something.." ></textarea>
                     <input type="submit" value="Submit" />
                 </form>
                 <div id="success" className="success">Message sent, we get in touch!</div>
              </div>
              <div className='contact-logo'>
                  <img src={bulldog} alt="English bulldog logo" height="320" width="200" />
              </div>
          </div>
  </div>;
}

export default Contact;
