import React, { useContext, useEffect, useReducer } from 'react'
import {createContext } from 'react'
import { Menu } from '../components/general/menu'
import { cartReducer } from './Reducers'

const Cart = createContext()

const Context = ({ children }) => {
    const products = Menu.map((item, id)=>({
        id: item.id,
        name: item.name,
        option: item.option,
        desc: item.desc,
        prices: item.prices,
        availlable: item.available,
        url: item.url

    }));

    // Initial state
const initialState = {
  products: products,
  cart: [],
  user: {
    username: "Guest",
    phone: "",
    email: "",
  }, // Example state (you can customize it based on your needs)
};

    const [state, dispatch] = useReducer(cartReducer, initialState, () => {
      // Initialize state from localStorage
      const localData = localStorage.getItem('guest-user-state');
      return localData ? JSON.parse(localData) : initialState;
  });

  useEffect(()=>{
    localStorage.setItem('guest-user-state', JSON.stringify(state))
  }, [state]);

  return (
    <Cart.Provider value={{state, dispatch}}>{children}</Cart.Provider>
  )
}

export default Context;

export const CartState = () =>{
    return useContext(Cart);
}