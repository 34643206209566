import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Context from './context/Context';

ReactDOM.render(<Context>
  <App/>
</Context>,
  document.getElementById('root')
);

