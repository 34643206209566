import React from 'react';
import './gaurantee.css';
import { Star } from '@material-ui/icons';

const Gaurantee = () => {
  return (<div className='about'>

      <div className='about-content'>
        <div className='about-header'>
            <h2>Russian blue kittens are 100% Guaranteed</h2>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;
            <div className='divider'></div>
        </div>
        <div className='about-desc'>
          <div className='about-us'>
            <p>
                IT IS HEREBY AGREED BY BOTH PARTIES THAT THE FOLLOWING CONDITIONS WILL BE MET AND THAT NO 
                OTHER WARRANTIES OR CONDITIONS ARE EXPRESSED OR IMPLIED. 
            </p>
            <br/>
            <p>
                BUYER certifies that he/she is not acting as an agent for another individual in the purchase 
                of this cat, and will not sell this cat to any mass-producing kennel (kitten mill) or business.
                 The purchaser promises to keep the cat in a proper manner. This kitten has been bred by us and 
                 has been carefully and painstakingly reared. The parent animals were mated with aim of 
                 breeding good and healthy kittens. Seller guarantees this cat to be free of communicable 
                 diseases as appears to the eye at the time of sale. HEALTH GUARANTEE At the expense of the 
                 BUYER, this cat should be examined by a licensed veterinarian within 3 BUSINESS DAYS of 
                 possession to validate Health Guarantee. Should kitten die from communicable disease (this 
                 does not include parasites or hypoglycemia) within 14 days of purchase and buyer promptly 
                 sought treatment by certified veterinarian, and if said vet certifies in writing that the 
                 kitten was thought to have been exposed to the disease prior to leaving our home, buyer will get
                  FULL refund of PURCHASE PRICE of kitten within 45 days of certification of death (or autopsy 
                  report, whichever comes last). Buyer must send certification (autopsy required only if vet is
                   unsure of actual cause of death) to Seller within 6 weeks of kitten’s death. If the kitten has a
                    genetic defect detected by a licensed veterinarian within the ONE year (guarantee ends 
                    when kitten turns ONE year old) a full refund will be made or another cat of equal value, the 
                    choice to be determined by Seller. Buyer must return kitten (at buyer’s expense) and 
                    kitten’s AKC paperwork to the Seller within 1 month of the exam that detected the defect 
                    before replacement will be made. Seller must also have a letter from that veterinarian 
                    with his address, telephone and the defect detected before any replacement kitten is given. 
                    If the Buyer refused to return this kitten for a replacement kitten then this guarantee is 
                    null and void. We the Sellers cannot guarantee the kitten’s size or color at the kitten’s 
                    adulthood. Due to the fact that the kitten leaves our home at a young age we the Sellers 
                    cannot guarantee that the kitten will breed or will “finish” in a cat show. Buyer is 
                    responsible for all transportation fees of original kitten and any replacement kitten. We, 
                    the Sellers, are not responsible for veterinarian bills or any other bills once the kitten 
                    leaves our home. Buyer releases Seller from any and all liabilities, and/or damages by 
                    fault of this cat after the time of sale. These damages include, but are not limited to, 
                    destruction of property and/or physical damage to any person or group of people.
            </p>
            <br/>
            
          </div>
        </div>

        </div>

  </div>);
}
export default Gaurantee;
