import React from 'react'

const MyLiveChat = () => {
    function add_chatinline(){
        var hccid=47322413;
        var nt=document.createElement("script");
        nt.async=true;nt.src="https://www.mylivechat.com/chatinline.aspx?hccid="+hccid;
        var ct=document.getElementsByTagName("script")[0];ct.parentNode.insertBefore(nt,ct);
    }
    add_chatinline();

    return null;
    
}
export default MyLiveChat;
