import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Available from './pages/Available';
import Policy from './pages/Policy';
import Contact from './pages/Contact';
import Navbar from './navbar/Navbar';
import Footer from './Footer/Footer';
import Shipping from './pages/shipping';
import Gaurantee from './pages/gaurantee';
import ScrollToTop from './ScrollToTop';
import MyLiveChat from './MyLiveChat';
import Cart from './pages/Cart';

function App() {
  return (
    <div className='container'>
      <Router>
        <ScrollToTop/>
        <Navbar/>
        <Routes>
           <Route path='/' exact element={<Available/>} />
           <Route path='/about' element={<About />} />
           <Route path='/policy' element={<Policy />} />
           <Route path='/contact' element={<Contact />} />
           <Route path='/shipping' element={<Shipping />} />
           <Route path='/guarantee' element={<Gaurantee />} />
           <Route path='/cart' element={<Cart />} />
        </Routes>
        <Footer/>
      </Router>
      <MyLiveChat/>
    </div>
  );
}

export default App;
