import Pork from '../images/Food/fried-pork.jpg';
import Tilapia1 from '../images/Food/tilapia1.jpg';
import Tilapia from '../images/Food/tilapia.png';
import Mackerel from '../images/Food/mackerel.jpg'
import Shawama from '../images/Food/shawama.jpg';
import Shawama1 from '../images/Food/shawama1.jpg';
import Chicken from '../images/Food/chicken.jpeg';
import Chicken1 from '../images/Food/chicken1.jpeg';
import Ribs from '../images/Food/ribs.jpg';
import Ribs1 from '../images/Food/ribs1.jpeg';
import Soya from '../images/Food/soya.jpeg'
import Soya1 from '../images/Food/soya1.jpg'
import Soya2 from '../images/Food/soya2.jpg'

export const Menu = [
    {
        id: 0,
        name: "Fried-Pork",
        option: [],
        desc: "",
        prices: [
            15, 20, 50, 100
        ],
        available: true,
        url: [
            Pork
        ]
    },
    {
        id: 1,
        name: "Roasted Mackerel",
        option: ["Fried Plantain", "Bobolo"],
        desc: "",
        prices: [
            25
        ],
        available: true,
        url: [
            Mackerel
        ]
    },
    {
        id: 2,
        name: "Roasted Tilapia",
        option: ["Fried Plantain", "Bobolo"],
        desc: "",
        prices: [
            25
        ],
        available: true,
        url: [
            Tilapia,
            Tilapia1
        ]
    },
    {
        id: 3,
        name: "Soya",
        option: [],
        desc: "",
        prices: [
            15, 20, 50, 100
        ],
        available: true,
        url: [
            Soya,
            Soya1,
            Soya2
        ]
    },
    {
        id: 4,
        name: "Roasted Chicken",
        option: ["Fries", "Fried Plantains"],
        desc: "",
        prices: [
            20
        ],
        available: true,
        url: [
            Chicken,
            Chicken1
        ]
    },
    {
        id: 5,
        name: "Ribs",
        option: [],
        desc: "Available only on demand",
        prices: [
            50, 100
        ],
        available: false,
        url: [
            Ribs,
            Ribs1
        ]
    },
    {
        id: 6,
        name: "Shawama",
        option: [],
        desc: "",
        prices: [
            15, 20
        ],
        available: true,
        url: [
            Shawama,
            Shawama1
        ]
    }   
]