import { Star } from '@material-ui/icons';
import React from 'react';
import Footer from '../Footer/Footer';
import Image from '../images/puppies/Rex/Rex1.jpg';
import './About.css';

const About = () => {
  return <div className='about'>
      <div className='about-content'>
        <div className='about-header'>
            <h1>About Us</h1>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;
            <div className='divider'></div>
        </div>
        <div className='about-desc'>
          <div className='about-us'>
            <p>
                Ashley Williem Russian Blue Cattery  was started a few years ago as a small family Russian kitten breeding program that 
                focuses on health, temperament and socialization. Our cats are first and foremost beloved family 
                pets. They receive high quality nutrition, great care, lots of love, affection as well as 
                training and stimulation. But our vision was much bigger than that. As some of you know we 
                worked hard for the past few months at researching and vetting out different breeders. We finally
                 narrowed it down to a few who we felt comfortable with and know have the same vision of 
                 producing healthy Russian kitten. So as of November 2019, the kitten on our website will not 
                 be only bred by us directly, but also by our group of Morgans breeders.
            </p>
            <br/>
            <p>
                WE’RE PASSIONATE About what we do, but more importantly we understand the frustrations of 
                searching high and low while trying to avoid scams and kitten mills alike. We work diligently 
                to ensure that we are following the best Russian kitten breeding practices and work extra hard to make 
                sure your future family member is healthy & socialized to fit your specific family’s needs. 
                After getting one of our Russian kitten we offer life-time support when it comes to caring and 
                raising your new Russian kitten or multiple Russian kittens.
            </p>
            <br/>
            <p>
                We started raising Russian kittens because of what we saw as a lack of transparency and the proliferation
                 of scam artists taking advantage of people when they are most vulnerable.The goal is to make 
                 Russian kitten Besties Home a website where every potential Russian kitten adopter comes and feels completely 
                 comfortable and at peace with knowing these kitten were raised in real homes with loving people 
                 that are passionate and want to improve the overall health of the breed. We are completely 
                 transparent about which kitten were bred by us directly and which came from other breeding 
                 homes. After you take the kitten home you are put in direct contact with the original breeder 
                 and can send them updates, ask questions and form a relationship. They care about the 
                 placement of their babies and would of course prefer to keep in touch and watch their little 
                 Russian kitten grow.
            </p>
          </div>
          <div className='about-photo'>
            <img src={Image} alt="Russian kitten kitten" width="300" height="470" />
          </div>
        </div>
      </div>
     
  </div>;
}

export default About;
