import { Facebook, Instagram, LocalActivityRounded, LocationCityRounded, LocationOn, Phone, Star, Twitter } from '@material-ui/icons';
import React from 'react';
import './Footer.css';
import { CompanyInfo } from '../company/CompanyDetails';

const Footer = () => {
  return ( <div className='footer'>
    <div className='media'>
      <div>
        <h3>Contacts</h3>
        <h6><Phone /> 614-648-5875</h6>
        <br />
      </div>
      <div className='address'>
        <h3>Address</h3>
        <p><LocationOn /> 2200 Pine Tree ln, Reynoldsburg Ohio 43068</p>
        <br />
      </div>
      <div className='social'>
        <h3>Social Media</h3>
        <ul>
          <li><Facebook /> Facebook</li>
          <li><Instagram /> Instagram</li>
        </ul>
        <br />
      </div>
    </div>
      <div className='copyright'>
        <Star style={{color:'white', fontSize:20}} /> &nbsp;&nbsp;
        <h7>© 2024 by dukeware.org (Renzo designed) </h7>
      </div>
      
  </div>);
}

export default Footer;
