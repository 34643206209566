import { useState } from 'react';
import { CartState } from '../../context/Context';
import './card.css';


const Card = props => {
    const prices = props.item.prices.map((price, index)=>{
        return (
            <option value={price}>${price}</option>
        )
    })
    const options = props.item.option.map((option, index)=>{
        return(
            <option value={option}>{option}</option>
        )
    })
    //set input field
    const [price, setPrice] = useState(props.item.prices[0]);
    const [option, setOption] = useState(props.item?.option[0]);
    const [product, setProduct] = useState({});

    const productSubmit =(e)=>{
        e.preventDefault();
        setProduct(

        )
    }

    const {state:{cart}, dispatch,} = CartState()
    const optionArray = props.item.option;
    return(
        <div className="card">
           <div className="card-name">
               <img src={props.item.url[0]} alt="pom" className="card-image"/>
               <form onSubmit={productSubmit} className='form'>
                <div className='item-price'>
                    
                    {props.item.prices.length === 1 ? <span>{props.item.name} <h4>${props.item.prices[0]}</h4></span> :
                     <span>{props.item.name} <h4><select onChange={(e)=>(setPrice(e.target.value))}>{prices}</select></h4></span>}
                </div>
                {optionArray.length > 0 && <div className='option'><select onChange={(e)=>(setOption(e.target.value))}>{options}</select></div>} 
                <div className='dine'> 
                    <input type='radio' name='dine' value="Dine in" id='dine-in' />
                    <label htmlFor='dine-in'>Dine in</label>
                    &nbsp;&nbsp;&nbsp;
                    <input type='radio' name='dine' value="Take Away" id='take-away' />
                    <label htmlFor='dine-in'>Take Away</label>
                </div>
               
               <div>
                {cart.some((p)=> p.id === props.item.id) ? 
                (<button type='submit' onClick={()=>{
                    dispatch({
                        type: 'REMOVE_FROM_CART',
                        payload: {
                            id: props.item.id,
                            pic: props.item.url[0],
                            name: props.item.name,
                            option: option,
                            price: price
                        }
                    })
                }} className="card-btn">Remove </button>) : 
                (<button type='submit' onClick={()=>{
                    dispatch({
                        type: 'ADD_TO_CART',
                        payload: {
                            id: props.item.id,
                            pic: props.item.url[0],
                            name: props.item.name,
                            option: option,
                            price: price
                        }
                    })
                }} className="card-btn">Add to cart</button>)
                }
                <button className="card-btn">Checkout</button>
              </div>
            </form>
           </div>
           
        </div>
    )
}
export default Card;