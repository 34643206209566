import React from 'react';
import './Policy.css';
import bull from '../images/puppies/Kim/Kim1.jpg';
import { Star } from '@material-ui/icons';

const Policy  = () => {
  return <div className='about'>
      <div className='about-content'>
        <div className='about-header'>
            <h1>Privacy Policy</h1>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;&nbsp;<Star style={{color:'green'}}/>&nbsp;
            <div className='divider'></div>
        </div>
        <div className='about-desc'>
          <div className='about-us'>
            <h2>SHIPPING</h2>
            <br/>
            <p>
                <img src={bull} alt='Russian blue kittens' height="300" width="280" />
                  We reserve the right to refuse to sell our Russian blue kittens to anyone, at anytime before

                  they go home, without a reason given. All Russian blue kittens will have their claws

                  removed, de-wormed several times. First set of shots and will be Microchipped

                  before they leave our home. All our babies are fully evaluated and checked by

                  our Vet. Russian blue kittens are sold on Spay/Neuter Contracts only. All pet/companion

                  Russian blue kittens are required to be spayed/neutered by the time they are 6 months old.

                  (We do offer full breeding rights to approved breeders only. We have very good

                  breeding lines and do protect our Russian blue kittens from becoming livestock for

                  breeding!) We require proof of spayed/neutered certificate from your Vet, by 7

                  months of the age of your kitten. Not doing so does give us the legal right to

                  demand the return of said kitten at your expense and to use legal measures

                  to ensure said kitten’s safe return. It is important to have your kitten spayed

                  or neutered! You must sign a contract agreeing to this before you can take your

                  kitten home. We reserve the right to refuse to sell our Russian blue kittens to anyone, at

                  anytime before they go home, without a reason given. The buyer has three

                  days after receipt of the kitten to have him/her examined by your Veterinarian

                  or health guarantee is null and void. You must have proof of this visit with-in the

                  time frame stated above. (Keep a copy of it in your kitten kit for safe keeping).

                  In the unlikely event that your kitten is found by your Veterinarian to be seriously ill within this time
                  period, you may request an exchange upon returning the kitten to us. If no more Russian blue kittens are available 
                  in your litter, you will have first pick in next litter of same Russian blue kittens. We do NOT refund money for a 
                  kitten for any reason, but will replace a kitten if there is ever a serious health issue that is covered 
                  under our Health Guarantee. We will not be responsible for contagious disease contracted after kitten has 
                  left our care. Remember that cat Parks can cause health issues to your kitten, also visiting another cat 
                  that does not have all it’s shots or worming. Buyer must maintain this cat in good health providing 
                  Veterinary care to insure continued good health and have accurate records as proof for the warranty on your 
                  kitten. You are required to take the kitten for a yearly examine by a certified Vet. Keep all required shots 
                  current and up to date. And must give them good quality cat food, and not let them become over weight. We do 
                  require you to sign a contract stating that, if for any reason, at any time in your kitten’s life, you find 
                  that you can not keep him/her that they must be returned to us. As we do not want any of our Russian blue kittens to end 
                  up in a shelter, rescue, or bad home. We take responsibility for our breeding program and will ALWAYS take 
                  back one of our Russian blue kittens. We have a list of elderly people that are waiting for an older cat, we will give 
                  these cats to them free of charge.
            </p>
          </div>
        </div>
      </div>
  </div>;
}

export default Policy;

