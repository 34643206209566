import React from 'react';
import { NavLink } from 'react-router-dom';
import { CompanyInfo } from '../company/CompanyDetails';
import "./Navbar.css";
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../images/aloy5.png';
import { ShoppingCart } from '@material-ui/icons';
import { CartState } from '../../context/Context';
import { orange } from '@material-ui/core/colors';


const Navbar = () => {
    const [clicked, setClicked] = useState(false);

    const {state:{cart},} = CartState();

    const handleClick = () =>{
        setClicked(!clicked);
    }

  return (
  <nav>
      <div className='contact-menu'>
          <div className='sms-menu'>
              <span></span>
          </div>
          <div className='email-menu' >
              <span>Phone: {CompanyInfo.phone} / {CompanyInfo.phone1} / {CompanyInfo.phone2} </span>
          </div>
      </div>
      <div className='menu-list'>
          <div className='logo-bar'>
              <a href="/">
                  <img src={logo} alt="Aloysweet int llc" height="160" width="200"  />
              </a>
            <div className='cart'>
              <NavLink style={({ isActive })=>({
                        color: isActive ? 'red' : 'blue',
                        textDecoration: isActive ? 'none' : 'none'
                    })} exact activeClassName="active" to="/cart" > <ShoppingCart /><span>{cart.length}</span></NavLink>
            </div>
              <div className='menu-icon' onClick={handleClick}>
                   {clicked? <CloseIcon/> : <MenuIcon/> }
              </div>
          </div>
          <div className={clicked? "menu" : "menu close"} >
          <ul>
                <li key="1">
                    <NavLink exact activeClassName="active" to="/" >Menu </NavLink>
                </li>
                <li key="2">
                    <NavLink  to="#" >About Us</NavLink>
                </li>
                <li key="3">
                    <NavLink exact activeClassName="active" to="#" >Contact </NavLink>
                </li>
                <li key="1">
                    <NavLink exact activeClassName="active" to="/cart" > <ShoppingCart />{cart.length}</NavLink>
                </li>
      </ul> 
          </div>
      </div>  
 </nav>
  );
}

export default Navbar;
