import React from 'react';
import './Available.css';
import { Star } from '@material-ui/icons';
import { useState } from 'react';
import { Menu } from '../general/menu';
import ReactPaginate from 'react-paginate';
import Card from '../general/card';
import { CartState } from '../../context/Context'

const Available = (props) => {
    const {state:{ products }, 
   
  } = CartState();
    console.log(products);
    const [items, setItems] = useState(products);
    const [pageNumber, setPageNumber] = useState(0);
    
    const itemsPerPage = 12;
    const pageVisited = pageNumber * itemsPerPage;
    const displayMenu = products.slice(pageVisited, pageVisited + itemsPerPage)
    .map((prod, index) =>{
        return (
            <Card item={prod} index={prod.id} />
        )
    });

    const totalPages = Math.ceil(items.length / itemsPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    };



  return <div className='available'>
      <div className='available-header'>
      <div className='divider'></div>&nbsp;&nbsp;<Star style={{color:'#F52F81'}}/>&nbsp;&nbsp;<Star style={{color:'#F52F81'}}/>&nbsp;<h1>Menu</h1>&nbsp;&nbsp;<Star style={{color:'#F52F81'}}/>&nbsp;&nbsp;<Star style={{color:'#F52F81'}}/>&nbsp;
            <div className='divider'></div>
      </div>
      <div className='available-food'>
        {displayMenu}
      </div>
      <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      pageCount={totalPages}
                      onPageChange={changePage}
                      containerClassName={"pagination"}
                      previousLinkClassName={"prev-btn"}
                      nextLinkClassName={"next-btn"}
                      disabledClassName={"pagination-disable"}
                      activeClassName={"active-page"}

                    />
  </div>;
}

export default Available;

